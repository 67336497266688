import React from 'react';
import { useTranslation } from '@wix/yoshi-flow-editor';

import { EmptyState } from '../../EmptyState/EmptyState';
import { MembershipButton } from '../../Membership/MembershipButton';

import { classes } from './Private.st.css';

export const Private: React.FC = () => {
  const { t } = useTranslation();

  return (
    <EmptyState
      className={classes.emptyState}
      title={t('groups-web.group.private')}
      content={t('groups-web.join.private')}
      button={<MembershipButton biOrigin="group_feed_inside_page_btn" />}
      dataHook="request-to-join"
    />
  );
};

Private.displayName = 'Private';
